<template>
  <div>
    <div class="text-muted text-center">
      {{ $t('help.we_sent_2auth_code_to_email') }}
    </div>
    <div class="d-flex justify-content-center align-items-center verification-form-wrapper mt-10">
      <InputFormGroup v-for="idx in 4" :key="idx" :value="form[idx]" ref="codeInput" numeric single-value @input="onInput(idx, $event)"/>
    </div>

    <div class="d-flex flex-wrap justify-content-center">
      <span
        class="text-muted font-weight-bold"
        :class="allowResendCode ? 'text-hover-primary cursor-pointer' : 'cursor-not-allowed'"
        v-on="allowResendCode ? { click: resendCode } : {}"
      >
        {{ $t('btn.resend_code') }}
        <span v-if="timerCount">00:{{ computedTimerCount }}</span>
      </span>
    </div>

    <b-form-checkbox class="mt-5 text-center" v-model="trustedDevice">
      {{ $t('label.confirm_trusted_device') }}
    </b-form-checkbox>

    <div class="form-group d-flex flex-wrap justify-content-center mt-5">
      <button
        ref="kt_login_signin_submit"
        class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
        :class="{'disabled': !codeValid}"
        :disabled="!codeValid"
        @click="submitForm"
      >
        {{ $t('btn.submit') }}
      </button>
    </div>
  </div>
</template>

<script>
const RESEND_CODE_IN_SECONDS = 60;
import {VERIFY_DEVICE, VERIFY_AUTH, RESEND_CODE} from '@/store/core/auth.module';
import LocalStorageService from '../../core/services/local-storage.service';
import InputFormGroup from '@/components/elements/form-groups/InputFormGroup';

export default {
  name: 'login',
  components: {
    InputFormGroup
  },
  data() {
    return {
      allowResendCode: false,
      trustedDevice: false,
      timerCount: LocalStorageService.getResendCodeTimer(),
      form: []
    };
  },
  async beforeMount() {
    this.$emit('title', this.$t('title.confirmation'));
  },
  computed: {
    code() {
      return this.form.join('');
    },
    computedTimerCount() {
      return this.timerCount > 9 ? this.timerCount : `0${this.timerCount}`;
    },
    codeValid() {
      return this.code.length === 4;
    }
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
            LocalStorageService.saveResendCodeTimer(value);
          }, 1000);
        } else {
          LocalStorageService.destroyResendCodeTimer();
        }

        this.allowResendCode = !value;
      },
      immediate: true
    }
  },
  methods: {
    onInput(idx, val) {
      this.$set(this.form, idx, val);
      if (idx < 4) {
        if (val) this.$refs['codeInput'][idx].$el.children[0].focus();
      } else {
        this.$refs['codeInput'][idx - 1].$el.children[0].blur();
      }
    },
    resendCode() {
      this.$store.dispatch(RESEND_CODE).finally(() => {
        this.timerCount = RESEND_CODE_IN_SECONDS;
      });
    },
    submitForm() {
      this.$store.dispatch(this.trustedDevice ? VERIFY_DEVICE : VERIFY_AUTH, {code: this.code})
        .then(() => {
          LocalStorageService.saveAuthVerified();
          this.$router.push({ name: this.$defaultRoute }).catch(() => {});
        })
        .finally(() => {
          this.form = [];
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.verification-form-wrapper::v-deep {
  .form-group {
    &:not(:last-child) {
      margin-right: 20px;
    }

    input {
      height: 65px;
      width: 50px;
      text-align: center;
      font-size: 26px;
      font-weight: 500;
    }
  }
}

.custom-checkbox::v-deep {
  .custom-control-label {
    line-height: 20px;
  }
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.orbi-logo {
  width: 150px;
}
</style>
